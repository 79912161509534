import type { ButtonProps } from 'flowbite-react';
import { Button } from 'flowbite-react';
import React from 'react';

import LoadingSpinner from '@/components/LoadingSpinner';

interface ButtonWithLoadingProps extends ButtonProps {
  isLoading: boolean;
  innerClassName?: string;
}

function ButtonWithLoading({
  isLoading,
  children,
  disabled,
  className = '',
  innerClassName = '',
  ...props
}: ButtonWithLoadingProps) {
  return (
    <Button
      {...props}
      color={props.color ?? 'primary'}
      className={`${className} relative ${
        isLoading ? 'disabled:opacity-100' : ''
      }`}
      disabled={disabled || isLoading}
    >
      <div
        className={`${isLoading ? 'invisible' : 'visible'} ${innerClassName}`}
      >
        {children}
      </div>
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center p-2">
          <LoadingSpinner className="h-full" />
        </div>
      )}
    </Button>
  );
}

export default ButtonWithLoading;
