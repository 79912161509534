import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSession, useSupabaseClient } from '@supabase/auth-helpers-react';
import { Label, TextInput } from 'flowbite-react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import ButtonWithLoading from '@/components/ButtonWithLoading';
import LoadingScreen from '@/components/LoadingScreen';
import { Meta } from '@/layouts/Meta';
import { validateEmail } from '@/utils/utils';

const UserAuthData = z
  .object({
    email: z
      .string()
      .min(1, { message: 'Please enter a valid email' })
      .refine((e) => validateEmail(e)),
    password: z
      .string()
      .min(6, { message: 'Password is too short' })
      .max(100, { message: 'Password is too long' }),
  })
  .required();

type AuthData = z.infer<typeof UserAuthData>;

const AuthPage = () => {
  // session and supabaseClient declarations
  const session = useSession();
  const supabase = useSupabaseClient();
  const router = useRouter();
  const redirectTo = (router.query.redirectedFrom as string) || '/app';
  const [error, setError] = React.useState<string | null>(null);

  const createDefaultValues = (): AuthData => ({
    email: '',
    password: '',
  });

  const { register, handleSubmit, formState } = useForm<AuthData>({
    defaultValues: createDefaultValues(),
    resolver: zodResolver(UserAuthData),
  });

  async function validateQueryParamsState() {
    const { error: queryError, err_description: errorDescription } =
      router.query;

    if (queryError || errorDescription) {
      setError((errorDescription || queryError) as string);
    }
  }

  const onSubmit: SubmitHandler<AuthData> = async (data) => {
    if (!data) return;

    const { data: signInResult, error: signInError } =
      await supabase.auth.signInWithPassword({
        email: data.email,
        password: data.password,
      });

    if (signInError) {
      setError(signInError.message);
      return;
    }

    if (signInResult) {
      router.push(redirectTo);
    }
  };

  useEffect(() => {
    if (session?.user) {
      router.push(redirectTo);
    }
  }, [session]);

  useEffect(() => {
    validateQueryParamsState();
  }, [router.query]);

  return (
    <main className="relative h-screen w-full">
      <Meta title="Lebra | Get Started" />

      {/* Background gradient */}
      <div className="absolute inset-x-0 z-0 hidden h-[150%] w-full translate-y-[-35%] md:block">
        <Image
          src="/images/landing_v2/gradient-background.png"
          alt="Platform"
          fill
          className="object-fill"
        />
      </div>

      {session ? (
        <LoadingScreen />
      ) : (
        <div className="relative z-20 flex h-full flex-col items-center pt-20">
          <Image alt="Lebra" src="/images/logo.svg" width={115} height={30} />
          <div className="container mx-auto mt-8 max-w-md border-blue-50 bg-white p-4 md:mt-12 md:rounded-[20px] md:border-4 md:p-10">
            <h1 className="text-2xl font-semibold text-gray-900">Sign in</h1>
            <p className="mt-1 text-base text-gray-500 md:text-lg">
              to continue to Lebra
            </p>

            <form onSubmit={handleSubmit(onSubmit)} className="mt-6">
              {error && (
                <div className="mb-6 flex items-center rounded-lg bg-red-100 p-2">
                  <InformationCircleIcon className="mr-2 h-4 w-4 text-red-700" />
                  <p className="text-sm text-red-700">{error}</p>
                </div>
              )}
              <div className="mb-1 block text-sm font-medium text-gray-900">
                <Label
                  htmlFor="email"
                  color={formState.errors?.email ? 'failure' : undefined}
                  value="Email address"
                />
              </div>
              <TextInput
                id="email"
                type="text"
                placeholder="Email address"
                {...register('email')}
                aria-invalid={formState.errors?.email ? 'true' : 'false'}
                color={formState.errors?.email ? 'failure' : 'light'}
                helperText={
                  formState.errors?.email && (
                    <React.Fragment>
                      <span className="font-medium">Oops!</span>{' '}
                      {formState.errors.email.message}
                    </React.Fragment>
                  )
                }
                className="rounded-lg border-gray-300 bg-gray-50"
              />
              <div className="mb-1 mt-4 block text-sm font-medium text-gray-900">
                <Label
                  htmlFor="password"
                  color={formState.errors?.password ? 'failure' : undefined}
                  value="Password"
                />
              </div>
              <TextInput
                id="password"
                type="password"
                placeholder="Your password"
                {...register('password')}
                aria-invalid={formState.errors?.password ? 'true' : 'false'}
                color={formState.errors?.password ? 'failure' : 'light'}
                helperText={
                  formState.errors?.password && (
                    <React.Fragment>
                      <span className="font-medium">Oops!</span>{' '}
                      {formState.errors.password.message}
                    </React.Fragment>
                  )
                }
              />
              <ButtonWithLoading
                type="submit"
                isLoading={formState.isSubmitting}
                className="mt-4 w-full bg-blue-600 md:mt-6"
              >
                Sign in
              </ButtonWithLoading>
            </form>

            <div className="mt-4 text-center text-sm text-gray-500 md:mt-6">
              <Link href="/auth/forgot-password">Forgot your password?</Link>

              <p className="mt-2 md:mt-4">
                Don&apos;t have an account?{' '}
                <Link href="/demo">Request a demo</Link>
              </p>
            </div>
          </div>
          <div className="mx-auto mt-6 max-w-md px-4 text-left text-xs text-gray-500 md:px-0">
            Lebra is not intended for the transmission or storage of any
            personally identifiable health information (PHI). Any information
            shared or entered into this platform should be free of any medical
            data protected under the Health Insurance Portability and
            Accountability Act (HIPAA). By using Lebra, you acknowledge and
            agree to the terms of this privacy and security disclaimer.
          </div>
        </div>
      )}
      <p className="absolute inset-x-0 bottom-8 z-30 text-center text-sm text-gray-700">
        <Link href="/terms-of-service" target="_blank">
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link href="/security-and-privacy-policy" target="_blank">
          Privacy Policy
        </Link>
      </p>
    </main>
  );
};

export default AuthPage;
